<template>
  <component :is="rowComponent"></component>
</template>

<script>
import { ExperienceTypesEnum } from '/src/common.js';
export default {
  name: "EditExperienceRow",
  components: {
    CreateInventoryRow: () => import("./CreateInventoryRow.vue"), 
    CreatePartnerExperienceRow: () => import('./CreatePartnerExperienceRow.vue'),
    CreateExternalExperienceRow: () => import('./CreateExternalExperienceRow.vue')
  },
  computed: {
    rowComponent() {
      if (this.$route.query.op === 'create') {
        switch (this.$route.query.expType) {
          case ExperienceTypesEnum.INVENTORY:
            return 'CreateInventoryRow';
          case ExperienceTypesEnum.PARTNER_EXPERIENCE:
            return 'CreatePartnerExperienceRow';
          case ExperienceTypesEnum.EXTERNAL_INTEGRATION:
            return 'CreateExternalExperienceRow';
        }
      } else if (this.$route.query.op === 'edit') {
        switch (this.$route.query.sourceType.toString()) {
          case '0':
            return 'CreateInventoryRow';
          case '1':
            return 'CreatePartnerExperienceRow';
          case '2':
            return 'CreateExternalExperienceRow';
        }
      }
    }
  }
};
</script>